$breakpoint-tablet: 768px;
$breakpoint-mobild: 480px;

body {
  background: linear-gradient(134deg, #eda87f, #dec0c7, #edd5c7);
  background-size: 800% 800%;

  -webkit-animation: landingpage-bg 8s ease infinite;
  -moz-animation: landingpage-bg 8s ease infinite;
  animation: landingpage-bg 8s ease infinite;
}

@-webkit-keyframes landingpage-bg {
  0% {
    background-position: 14% 0%;
  }
  50% {
    background-position: 87% 100%;
  }
  100% {
    background-position: 14% 0%;
  }
}
@-moz-keyframes landingpage-bg {
  0% {
    background-position: 14% 0%;
  }
  50% {
    background-position: 87% 100%;
  }
  100% {
    background-position: 14% 0%;
  }
}
@keyframes landingpage-bg {
  0% {
    background-position: 14% 0%;
  }
  50% {
    background-position: 87% 100%;
  }
  100% {
    background-position: 14% 0%;
  }
}

.landing-page {
  display: flex;
  padding: 4rem;
  flex-direction: column;

  @media (max-width: $breakpoint-tablet) {
    padding: 3rem;
  }

  header {
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column-reverse;
    }

    .header-left {
      h1 {
        font-family: "ClashDisplay-Variable", sans-serif;
        font-weight: 700;
        font-size: 5rem;
        line-height: 110%;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
        max-width: 788px;
        color: #0b062a;

        @media (max-width: $breakpoint-tablet) {
          font-size: 3rem;
        }
      }
    }

    .header-right {
      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 2rem;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    padding-bottom: 64px;

    p {
      max-width: 300px;
      font-family: "Clash Display", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #0b062a;
    }

    a {
      font-family: "Clash Display", sans-serif;
      font-weight: 500;
      background-color: #0b062a;
      padding: 16px 32px;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
    }
  }
}
